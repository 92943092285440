<template>
  <div>
    <ModalSuccess
      v-show="showSuccessModal"
      @close="closeModal"
      class="-mt-40 pt-60 pb-60 px-4 md:px-8 md:pt-96 lg:pt-72 lg:px-20 xl:px-60 xl:-mt-40 xl:pt-40 2xl:px-96 2xl:-mt-40 2xl:pt-60"
    />
    <ModalFailed
      v-show="showFailedModal"
      @close="closeModal"
      class="-mt-40 pt-60 pb-60 px-4 md:px-8 md:pt-96 lg:pt-72 lg:px-20 xl:px-60 xl:-mt-40 xl:pt-40 2xl:px-96 2xl:-mt-40 2xl:pt-60"
    />
    <div class="bg-whiteBase xl:shadow-inner xl:pt-24">
      <!-- <div class="absolute align-middle bottom-0 right-0">
        <a href="https://wa.me/6281299556161" target="_blank">
          <img
            src="../assets/wa.svg"
            alt=""
            class="w-2/12 h-aut0 fixed z-40 right-0 object-right p-3 -mt-32 -mr-1 md:p-8 md:-mr-4 md:-mt-48 lg:p-14 lg:-mr-9 lg:-mt-52 xl:p-iconWa xl:-mt-56 xl:-mr-16 2xl:-mt-72 2xl:p-32 2xl:-mr-28"
        /></a>
        <svg
          v-show="visible"
          id="backToTopID"
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          fill="currentColor"
          class="bi bi-arrow-up-circle-fill text-blackBlue fixed z-50 right-0 object-right -mt-16 mr-1 px-2 md:px-0 md:mr-6 md:-mt-20 xl:-mt-16 xl:p-1 2xl:-mt-24 2xl:mr-4 cursor-pointer"
          viewBox="0 0 16 16"
          @click="scrollToTop"
        >
          <path
            d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
          />
        </svg>
      </div> -->
      <!-- Start Kontak Header -->
      <div>
        <img
          src="../assets/background/bg-top.webp"
          alt=""
          class="img1 w-10/12 ml-bgMobile pl-20 backdrop-opacity-80 md:w-11/12 md:ml-bgTab md:pl-40 md:-mt-8 lg:ml-bgLg xl:hidden"
        />
        <h5
          class="tracking-widest mb-2 -mt-44 xl:mt-0 xl:text-2xl text-center lg:text-3xl lg:mt-mtBerandaButtonBottomDekstop text-base md:-mt-80 md:text-2xl"
        >
          HUBUNGI KAMI
        </h5>
        <h1
          class="text-center text-2xl mx-20 md:mx-48 md:leading-tight md:text-4xl lg:mx-60 xl:mt-0 xl:text-5xl lg:text-5xl xl:leading-tight xl:mx-96 xl:px-10 2xl:mx-mlBerandaBottomRightLG 2xl:px-20"
        >
          Kami Bersedia Merespon Setiap Pertanyaan Anda
        </h1>
        <hr
          class="beranda border mt-2 absolute inset-x-36 md:w-1/4 md:mx-auto xl:w-1/6"
        />
        <p
          class="text-center text-base mt-8 mx-5 md:mt-14 md:text-xl md:mx-20 lg:text-xl lg:mx-52 2xl:mx-96 2xl:px-20"
        >
          Jika anda memiliki pertanyaan serta keluhan seputar layanan asuransi
          kami. Anda bisa mengisi data formulir dibawah ini dengan lengkap. Kami
          akan merespon pesan anda sesegera mungkin.
        </p>
      </div>
      <!-- End Kontak Header -->

      <div class="xl:flex xl:flex-row-reverse xl:mt-10 2xl:mx-28">
        <!-- Start Form Kontak -->
        <form
          name="formContact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          @submit.prevent="sendContactMail"
          class="w-full my-10 px-8 md:px-16 md:mt-20 lg:px-36 xl:px-20 xl:pl-72 2xl:pl-80 2xl:mt-20 relative"
        >
          <!-- hidden -->
          <input type="hidden" name="form-name" value="formContact" />

          <!-- form name, email, message -->
          <div
            class="bg-bgKontakForm bg-opacity-50 pt-3 xl:mt-14 xl:grid xl:grid-cols-2 xl:gap-6"
          >
            <div class="items-center border-b border-black py-2 xl:mt-0">
              <input
                class="appearance-none text-sm bg-transparent border-none w-full text-black mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="text"
                placeholder="Nama lengkap anda*"
                aria-label="Full name"
                name="name"
                id="name"
                v-model="formContact.name"
                required
              />
            </div>
            <div
              class="items-center border-b border-black py-2 my-4 xl:mb-0 xl:mt-0"
            >
              <input
                class="appearance-none text-sm bg-transparent border-none w-full text-black mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="text"
                placeholder="Email anda*"
                aria-label="Email Address"
                name="email"
                id="email"
                v-model="formContact.email"
                required
              />
            </div>
            <div
              class="items-center border-b border-black py-2 my-4 xl:col-span-2"
            >
              <textarea
                class="appearance-none text-sm bg-transparent border-none w-full text-black mr-3 py-1 px-2 leading-tight focus:outline-none h-24"
                type="text"
                placeholder="Pesan Anda"
                aria-label="Pesan Anda"
                name="message"
                id="message"
                v-model="formContact.message"
                required
              />
            </div>
          </div>
          <div
            class="flex items-start mb-6 mt-6 xl:col-span-2 xl:-ml-2 xl:mt-0"
          >
            <div class="md:w-1/12 xl:ml-20 xl:mt-">
              <input
                class="mr-2 leading-tight xl:-ml-16"
                type="checkbox"
                name="isCheck"
                id="isCheck"
                v-model="formContact.isCheck"
                required
              />
            </div>
            <label
              class="md:w-full block md:-ml-5 text-black text-left xl:-ml-20 2xl:-ml-28"
            >
              <span class="text-sm">
                Saya setuju bahwa data yang saya kirimkan
                <span class="font-semibold">dikumpulkan</span> dan
                <span class="font-semibold"> disimpan</span>
              </span>
            </label>
          </div>
          <div
            class="mx-auto w-full mt-4 md:m-0 md:w-1/3 xl:ml-0 2xl:mr-mtBerandaKarirButtonDekstop lg:ml-0"
          >
            <button
              :disabled="formContact.invalid"
              type="submit"
              class="form-button mt-3 tracking-wide focus:outline-none font-semibold py-2 text-whiteBase bg-gradient-to-r from-brownCLight to-brownA text-base px-3 border-none bg-darkBrown hover:bg-whiteBase hover:text-darkBrown w-full mb-4 disabled:bg-grey"
            >
              <svg
                v-if="isLoading"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="animate-spin bi bi-app-indicator"
                viewBox="0 0 16 16"
              >
                <path
                  d="M5.5 2A3.5 3.5 0 0 0 2 5.5v5A3.5 3.5 0 0 0 5.5 14h5a3.5 3.5 0 0 0 3.5-3.5V8a.5.5 0 0 1 1 0v2.5a4.5 4.5 0 0 1-4.5 4.5h-5A4.5 4.5 0 0 1 1 10.5v-5A4.5 4.5 0 0 1 5.5 1H8a.5.5 0 0 1 0 1H5.5z"
                />
                <path d="M16 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
              </svg>
              KIRIM
            </button>
          </div>
        </form>
        <div class="mx-6 md:mx-12 mt-24 lg:mx-20 lg:mt-36 xl:mt-20">
          <h3 class="text-main-office text-2xl md:text-2xl lg:text-3xl">
            MAIN OFFICE
          </h3>
          <div class="md:grid md:grid-cols-2 xl:grid-cols-1">
            <div class="flex mt-4">
              <img src="../assets/location.svg" alt="" class="mb-auto mt-1" />
              <div class="ml-2">
                <h3
                  class="tracking-widest text-xl mb-1 md:text-xl lg:text-2xl xl:-mr-72"
                >
                  ALAMAT KAMI
                </h3>
                <p class="-mr-20 md:text-lg lg:text-xl xl:-mr-72">
                  Ruko ITC BSD Blok R No.2 Jl. Pahlawan Seribu.
                </p>
                <p class="md:text-lg lg:text-xl xl:-mr-72">
                  BSD City Tangerang Selatan
                </p>
                <p class="md:text-lg lg:text-xl xl:-mr-72">Banten (15322)</p>
              </div>
            </div>
            <div class="flex mt-4 md:ml-28 xl:ml-0 xl:mt-12">
              <img src="../assets/at.svg" alt="" class="mb-auto mt-1" />
              <div class="ml-2">
                <h3 class="tracking-widest text-xl mb-1 lg:text-2xl">
                  EMAIL KAMI
                </h3>
                <p class="md:text-lg lg:text-xl">admin@troyaindo.co.id</p>
              </div>
            </div>
            <div class="flex mt-4 relative md:mt-8 xl:mt-12">
              <img src="../assets/phone.svg" alt="" class="mb-auto mt-1" />
              <div class="ml-2">
                <h3 class="tracking-widest text-xl mb-1 lg:text-2xl xl:-mr-72">
                  TELEPON KAMI
                </h3>
                <p class="md:text-lg lg:text-xl xl:-mr-72">+62 81299556161</p>
              </div>
            </div>
          </div>
        </div>
        <img
          src="../assets/background/bg-tentang.webp"
          alt=""
          class="-mt-40 mb-14 md:-mt-72 lg:-mt-96 xl:hidden"
        />
        <!-- End Form Kontak -->
      </div>
      <img
        src="../assets/background/bg-bottom.webp"
        alt=""
        class="hidden xl:block xl:mt-mtBerandaLayananLg 2xl:mt-mtBerandaDekstop"
      />

      <!-- Start Lokasi Kami -->
      <div class="mx-6 md:mx-12 lg:mx-16 xl:mb-24 2xl:mx-40 2xl:-mt-12">
        <h3
          class="text-main-office text-2xl md:text-3xl lg:text-4xl xl:text-5xl"
        >
          Lokasi Kami
        </h3>
        <hr class="klaim w-4/12 mt-3 md:w-1/5 2xl:relative 2xl:w-1/6" />
        <p
          class="mt-4 text-sm mr-8 mb-2 md:mt-8 md:text-lg mx-auto md:mr-0 lg:text-xl lg:pr-32 xl:mr-80 xl:pr-40 2xl:mr-mBerandaXL 2xl:pr-96"
        >
          Jika anda ingin mengunjungi kantor kami untuk Konsultasi seputar
          Layanan Asuransi, anda bisa langsung melihat lokasi kantor dibawah
          ini.
        </p>
        <div class="-mx-6 mt-4 mb-12 lg:-mx-10">
          <iframe
            width="100%"
            height="400"
            src="https://maps.google.com/maps?q=PT%20TROYA%20INDO%20PERKASA&t=&z=13&ie=UTF8&iwloc=&output=embed"
          ></iframe>
        </div>
      </div>
      <!-- End Lokasi Kami -->
    </div>
  </div>
</template>


<script>
import axios from "axios";
import ModalSuccess from "../components/ModalSuccess.vue";
import ModalFailed from "../components/ModalFailed.vue";

export default {
  name: "Kontak",
  components: {
    ModalFailed,
    ModalSuccess,
  },
  data() {
    return {
      visible: false,
      isCheck: false,
      showSuccessModal: false,
      showFailedModal: false,
      isLoading: false,
      formContact: {
        name: "",
        email: "",
        message: "",
        isCheck: ""
      },
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    closeModal() {
      this.showSuccessModal = false;
    },
    sendContactMail() {
      this.isLoading = true;
      const url = "https://troyaindo.co.id/.netlify/functions/contact";

      if (!this.validEmail(this.formContact.email)) {
        return (this.notice = "The email address is badly formatted.");
      } else if (this.formContact.message.length < 15) {
        return (this.notice = "Your message is too short");
      }

      this.isShow = true;
      this.notice = "";
      axios
        .post(url, this.formContact)
        .then(() => {
          this.showSuccessModal = true;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.showFailedModal = true;
        })
        .finally(() => {
          this.isShow = false;
          this.formContact = {};
        });
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>

<style>
p.atau {
  position: relative;
}

p.atau span {
  background-color: white;
  padding-right: 10px;
}

p.atau:after {
  content: "";
  position: absolute;
  bottom: 2px;
  left: 2rem;
  right: 0;
  height: 0.5em;
  border-top: 1px solid rgb(172, 172, 172);
  /* z-index: -1; */
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
</style>
