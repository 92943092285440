<template>
  <transition name="modal-fade">
    <div
      @click="close"
      class="modal-backdrop h-auto w-full rounded-md content-center relative"
      id="modal-picture"
    >
      <div
        @click.stop=""
        class="modal align-middle mb-20 md:mb-60"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <section class="modal-body text-center" id="modalDescription">
          <slot name="body">
            <h1
              class="relative text-center mt-5 text-3xl md:text-4xl md:mt-10 xl:mt-10 xl:text-4xl tracking-widest 2xl:text-5xl 2xl:mt-16"
            >
              Oopss!
            </h1>
            <p
              class="relative text-sm text-center md:text-xl md:px-20 mt-8 xl:mx-20 2xl:px-72 2xl:mt-16"
            >
              Maaf pesan kamu gagal terkirim!
            </p>
            <p
              class="relative text-center px-1 md:px-20 md:text-xl -mt-1 text-sm xl:mx-20 2xl:px-20 2xl:mt-1"
            >
              Silahkan kirim kembali pesan kamu
            </p>
            <img
              src="../assets/background/bg-bottom.webp"
              alt=""
              class="-mt-40 -mx-2 md:-mt-80 lg:-mt-96"
            />
            <router-link to="/beranda">
              <div
                class="mx-auto w-full -mt-7 md:mx-auto md:-mt-10 lg:-mt-14 md:w-1/3 xl:mx-auto xl:-mt-20 2xl:-mt-32 2xl:mx-auto"
              >
                <button
                  type="submit"
                  class="form-button mt-3 tracking-wide focus:outline-none font-semibold py-2 text-whiteBase bg-gradient-to-r from-brownCLight to-brownA text-base px-3 border-none bg-darkBrown w-6/12 md:w-full hover:bg-whiteBase hover:text-darkBrown xl:w-full -mb-4"
                >
                  Kembali ke Beranda
                </button>
              </div></router-link
            >

            <p
              class="text-center mt-7 md:mt-10 lg:mt-12 ml-5 text-sm xl:mt-8 2xl:mt-12"
            >
              ©2020 troyaindoperkasa | Layanan
            </p>
          </slot>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalFailed",
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style>
.modal-backdrop {
  position: fixed;
  background-color: rgb(15, 15, 15, 0.5);
  display: flex;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: #fefefe;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.modal-header,
.modal-footer {
  padding: 15px 23px;
  display: flex;
}

.modal-header {
  position: relative;
  /* border-bottom: 1px solid #eeeeee; */
  color: #4aae9b;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 0px 8px;
  cursor: pointer;
  font-weight: bold;
  color: black;
  background: transparent;
  outline: none;
}

.btn-green {
  color: black;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.img-modal {
  -o-object-fit: cover;
  object-fit: cover;
  background-size: cover;
}
</style>